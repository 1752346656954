<template>
    <div>
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <div class='details'>
            <div class='title'>{{detail.title}}</div>
            <div class='subtitle'>{{detail.subtitle}}</div>
             <div class='issueTime'>
                <span>{{detail.issueTime}}</span>
                <span>{{detail.issueOrganization}}</span>
            </div>
            <div class='imgPath'>
                <img :src='detail.imgPath' alt />
            </div>
            <div class='content'>{{detail.content}}</div>
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
            detail: '',
            breadList: [{
                name: '热点功能'
            }, {
                name: '政策匹配',
                path: 'policyMatching'
            }, {
                name: '详情'
            }]
        };
    },
    mounted() {
        if (this.$route.query && this.$route.query.id) {
            this.getDetail(this.$route.query.id);
        }
    },
    methods: {
        async getDetail(id) {
            const api = this.$fetchApi.policyDetail.api;
            const di = this.$route.query.id;
            const data = await this.$fetchData.fetchGet({}, api + id, 'json');
            if (data.code === '200') {
                this.detail = data.result;
            }
        }
    }
};
</script>
<style lang="less" scoped>
    .details{
        width:1180px;
        margin:0 auto;
        color: #5c5c5c;
        font-size:16px;
        white-space: pre-wrap;
        .title{
            color: #333333;
            font-weight: bold;
            font-size: 30px;
            margin-top:38px;
            text-align: center;
        }
        .issueTime{
            margin:19px 0 40px 0;
            text-align: center;
            span{
                margin-right: 20px;
            }
        }
        .subtitle{
            margin-top:19px;
            text-align: center;
            font-size:20px;
        }
        .imgPath{
            text-align: center;
            margin-bottom:20px;
            img{
                width: 600px;
               height: 400px;
            }
        }
        .content{
            line-height: 30px;
            text-indent: 2em;
            margin-bottom:120px;
        }
    }
</style>